import Checkout from '~/core/api/checkout';
import Classes from '~/core/api/classes';
import Tiers from '~/core/api/tiers';
import Course from '~/core/api/course';
import Preview from '~/core/api/preview';
import Session from '~/core/api/session';
import Student from '~/core/api/student';
import Passage from '~/core/api/passage';
import QuestionBank from '~/core/api/qbank';
import Support from '~/core/api/support';
import Flashcard from '~/core/api/flashcard';
import Radar from '~/core/api/radar';
import Platform from '~/core/api/platform';
import Podcast from '~/core/api/podcast';
import User from '~/core/api/user';
import DiscountAPI from '~/core/api/discount.api';
import ExamAPI from '~/core/api/exam.api';
import QuizAPI from '~/core/api/quiz.api';
import Ping from '~/core/api/ping';
import Dashboard from '~/core/api/dashboard';
import Library from '~/core/api/library';
export default (function (context, inject) {
  var $axios = context.$axios,
    $axiosRaw = context.$axiosRaw;
  var apiFactory = {
    checkout: Checkout($axios),
    course: Course($axios),
    preview: Preview($axios),
    session: Session($axios),
    student: Student($axios),
    classes: Classes($axios),
    tiers: Tiers($axios),
    passage: Passage($axios),
    qBank: QuestionBank($axios),
    support: Support($axios),
    flashcard: Flashcard($axios),
    radar: Radar($axios),
    platform: Platform(context),
    podcast: Podcast($axios),
    user: User($axios),
    discount: new DiscountAPI($axios),
    exam: new ExamAPI($axios),
    quiz: new QuizAPI($axios),
    ping: Ping($axiosRaw),
    dashboard: Dashboard($axios),
    library: Library($axios)
  };
  inject('api', apiFactory);
});